import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import tcdd from "../../assets/img/tcdd-basari-hikayesi.png"
import trafikArtis from "../../assets/img/tcdd-trafik-artisi.png"
import sorunlar from "../../assets/img/tcdd-sorunlar.png"
import cozum from "../../assets/img/tcdd-cozumler.png"
import tasarim from "../../assets/img/tcdd-tasarim.png"
import OtherCaseStudy from "../../components/other-case"

import eelbeyglu from "../../assets/img/teams/11.jpg"
import myildirim from "../../assets/img/teams/22.jpg"
import celal from "../../assets/img/teams/12.jpg"
import onay from "../../assets/img/teams/23.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="TCDD Taşımacılık Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>TCDD Taşımacılık</h1>
                <p style={{ maxWidth: 335 }}>TCDD Taşımacılık ile UI UX ve website altyapı geliştirme alanında 2017 yılında çalışmalara başlandı. </p>
                <p style={{ maxWidth: 335, fontSize: 15 }}>2019 yılının Ağustos ayında yayına aldığımız websitesi ile birçok sorunu çözerek bilet satış dönüşümlerini %24 artırdık.</p>
                <a className="svgLink" target="_blank" href="https://tcddtasimacilik.gov.tr/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>tcddtasimacilik.gov.tr</span></a>
                <img className="pakmaya-headimg" alt="TCDD Taşımacılık web sayfası bir bilgisayar ekranında açık durumda" style={{ position: 'absolute', height: 455, right: -90, top: 0, zIndex: -1 }} src={tcdd} />
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box">
                        <span>+%24</span>
                        <p>Dönüşüm oranı artışı.</p>
                    </div>
                    <div className="box">
                        <span>+%125</span>
                        <p>Organik trafik artışı.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Website Yazılım Geliştirme:</li>
                            <li>Website UI/UX:</li>
                            <li>SEO:</li>
                        </ul>
                        <ul>
                            <li><img src={myildirim} alt="Murthan Yıldırım" />Murathan Yıldırım</li>
                            <li><img src={celal} alt="Celal Yeşil" />Celal Yeşil</li>
                            <li><img src={onay} alt="Barış Onay" />Barış Onay</li>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu" />Emre Elbeyoğlu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="content-section content row" id="eCantaCozum">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Sorunlar</h3>
                    <p style={{ maxWidth: 429 }}>2019 yılının Ağustos ayında yayına aldığımız websitesi ile birçok sorunu çözerek bilet satış dönüşümlerini %24 artırdık.</p>
                    <p style={{ maxWidth: 429 }} className="small">- Bilet satın alma sürecindeki kullanılabilirlik hataları.</p>
                    <p style={{ maxWidth: 429 }} className="small">- Website içeriklerinin ve altyapının SEO uyumlu olmaması.</p>
                </div>
                <div className="col-md-6">
                    <img src={sorunlar} alt="tcdd taşımacılık mobil uygulama menü ekranı"/>
                </div>
            </div>
            <div className="content-section content row py60" >
                <div className="col-md-6">
                    <img className="pr100" src={cozum} alt="tcdd taşımacılık mobil uygulama menü ekranı"/>
                </div>
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Çözümler</h3>
                    <p style={{ maxWidth: 429 }} >SEO görünürlüğünü artırmak için yeni içerikler üreterek website altyapısı uyumlu hale getirildi.</p>
                    <p style={{ maxWidth: 429 }} > Website genel arayüzü, bilet alma deneyimini daha iyi hale getirmek için testler ve analizler yapılarak mevcut haline getirildi.</p>
                </div>
            </div>

            <div className="text-center content py60">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 50px', maxWidth: 500 }}>Doğru tasarım ile doğru bilet satış deneyimi</h2>
                <img src={tasarim} style={{ width: '100%' }} />
            </div>

            <div className="content-section content row" id="eCantaCozum">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3 style={{ maxWidth: 429 }}>1 senelik çalışma ile %125 organik trafik artışı</h3>
                    <p style={{ maxWidth: 429 }} className="small">Yapılan çalışmalar doğrultusunda yalnızca 1 sene gibi kısa bir süre içerisinde trafik oranlarında önemli gelişmeler kaydedildi.</p>
                    <h3 style={{ maxWidth: 429, fontSize: 29 }}>SEO faaliyetleri ile görünürlük artırıldı,  yeni altyapı ile büyük farklar yaratıldı.</h3>
                    <p style={{ maxWidth: 429 }} className="small">Grafiklerde görüldüğü üzere 2018 yılında aylık organik trafik 875.758 iken 2019’da bu rakam 1.812.767’ye yükseldi. </p>
                    <p style={{ maxWidth: 429, fontSize: 9 }} className="small">Bu başarıda en büyük etkenler; SEO alanında içerik zenginleştirmeleri, on-page SEO isterlerinin sağlanması ve yeni website altyapısı oldu.</p>
                </div>
                <div className="col-md-6">
                    <img src={trafikArtis} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
            </div>

        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
